<template>
  <div  class="sidebar-panel">
    <div class="gull-brand text-center p-4 d-flex justify-content-center align-items-center">
      <a>
        <!-- <i class="fas fa-home text-white fa-2x"></i> -->
         <img class="bg-white rounded" :src="`${publicPath}assets/images/logo.png`" width="100">
      </a>
      <div class="sidebar-compact-switch ml-auto"><span></span></div>
    </div>
    <div class="scroll-nav">
      <div class="side-nav">

<!--        <div id="sidebarIcon">-->
<!--          <i class="fa fa-spinner fa-spin text-white"></i>-->
<!--        </div>-->

        <div id="sidebarMenu" class="main-menu">

          <ul class="metismenu" id="menu" >
            <li class="Ul_li--hover mm-actives">
                  <a href="/"><i class="i-Bar-Chart text-20 mr-2 text-muted"></i><span class="item-name text-15 text-muted">{{$t('dashboard.text')}}</span></a>
                </li>
            <li   class="Ul_li--hover">
              <a class="has-arrow"   href="#">
                <i class="fa-solid fa-gear text-20 mr-2 text-muted"></i>
                <span class="item-name text-15 text-muted">{{ $t('basicData.text') }}</span>
              </a>

              <ul
                  class="mm-collapse "
              >

                <li class="item-name text-initial"  >
                  <router-link :to="{name: 'Currencies'}" class="link" >
                    <i class="fa-solid fa-dollar-sign mr-2"></i> <span class="text-muted"> {{ $t('currencies.text') }} </span>
                  </router-link>
                </li>
                <li class="item-name text-initial"  >
                  <router-link :to="{name: 'Countries'}" class="link" >
                    <i class="fa-solid fa-flag mr-2"></i> <span class="text-muted"> {{ $t('countries.text') }} </span>
                  </router-link>
                </li>
                <li class="item-name text-initial"  >
                  <router-link :to="{name: 'Sectors'}" class="link" >
                    <i class="fa-solid fa-vector-square mr-2"></i> <span class="text-muted"> {{ $t('sectors.text') }} </span>
                  </router-link>
                </li>
                <li class="item-name text-initial"  >
                  <router-link :to="{name: 'Fields'}" class="link" >
                    <i class="fa-solid fa-fill mr-2"></i> <span class="text-muted"> {{ $t('fields.text') }} </span>
                  </router-link>
                </li>

                <li class="item-name text-initial"  >
                  <router-link :to="{name: 'Units'}" class="link" >
                    <i class="fa-solid fa-building-un mr-2"></i> <span class="text-muted"> {{ $t('units.text') }} </span>
                  </router-link>
                </li>
                <li class="item-name text-initial"  >
                  <router-link :to="{name: 'Samples'}" class="link" >
                    <i class="fa-solid fa-chart-simple mr-2"></i> <span class="text-muted"> {{ $t('samples.text') }} </span>
                  </router-link>
                </li>
<!--                <li class="item-name text-initial"  >-->
<!--                  <router-link :to="{name: 'Packages'}" class="link" >-->
<!--                    <i class="fa-solid fa-cubes mr-2"></i> <span class="text-muted"> {{ $t('packages.text') }} </span>-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                <li class="item-name text-initial"  >-->
<!--                  <router-link :to="{name: 'BasicData'}" class="link" >-->
<!--                    <i class="fa-solid fa-gear mr-2"></i> <span class="text-muted"> {{ $t('basicData.text') }} </span>-->
<!--                  </router-link>-->
<!--                </li>-->

                <li class="item-name text-initial"  >
                  <router-link :to="{name: 'Products'}" class="link" >
                    <i class="fa-brands fa-product-hunt mr-2"></i> <span class="text-muted"> {{ $t('products.text') }} </span>
                  </router-link>
                </li>

                <li class="item-name text-initial"  >
                  <router-link :to="{name: 'Licenses'}" class="link" >
                    <i class="fa-brands fa-product-hunt mr-2"></i> <span class="text-muted"> {{ $t('licenses.text') }} </span>
                  </router-link>
                </li>

                <li class="item-name text-initial"  >
                  <router-link :to="{name: 'Alerts'}" class="link" >
                    <i class="fa-brands fa-product-hunt mr-2"></i> <span class="text-muted"> {{ $t('alerts.text') }} </span>
                  </router-link>
                </li>
                
              </ul>

            </li>
<!--            <li   class="Ul_li&#45;&#45;hover">-->
<!--              <a class="has-arrow"   href="#">-->
<!--                <i class="fa-solid fa-hand-holding-dollar text-20 mr-2 text-muted"></i>-->
<!--                <span class="item-name text-15 text-muted">{{ $t('clientProducts.text') }}</span>-->
<!--              </a>-->

<!--              <ul-->
<!--                  class="mm-collapse "-->
<!--              >-->
<!--                <li class="item-name text-initial"  >-->
<!--                  <router-link :to="{name: 'Products'}" class="link" >-->
<!--                    <i class="fa-brands fa-product-hunt mr-2"></i> <span class="text-muted"> {{ $t('products.text') }} </span>-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                <li class="item-name text-initial"  >-->
<!--                  <router-link :to="{name: 'ProductsPrices'}" class="link" >-->
<!--                  <i class="fa-solid fa-money-check-dollar mr-2"></i> <span class="text-muted"> {{ $t('productPrices.text') }} </span>-->
<!--                  </router-link>-->
<!--                </li>-->

<!--                <li class="item-name text-initial"  >-->
<!--&lt;!&ndash;                  <router-link :to="{name: 'Users'}" class="link" >&ndash;&gt;-->
<!--&lt;!&ndash;                  <i class="fa-solid fa-money-check-dollar mr-2"></i> <span class="text-muted"> {{ $t('users.text') }} </span>&ndash;&gt;-->
<!--&lt;!&ndash;                  </router-link>&ndash;&gt;-->
<!--                </li>-->
<!--              </ul>-->

<!--            </li>-->
            <li   class="Ul_li--hover">
              <router-link :to="{name: 'PromoCodes'}" class="link" >
                <i class="fa-solid fa-percent text-20 mr-2 text-muted"></i>
                <span class="item-name text-15 text-muted">{{ $t('PromoCodes.text') }}</span>
              </router-link>
            </li>

          <li class="Ul_li--hover">
              <router-link :to="{name: 'ProfilesRoles'}" class="link" >
                <i class="far fa-address-card text-20 mr-2 text-muted"></i>
                <span class="item-name text-15 text-muted">{{ $t('profilesRoles.text') }}</span>
              </router-link>
            </li>

            <li   class="Ul_li--hover">
              <a class="has-arrow"   href="#">
                <i class="fa-solid fa-users text-20 mr-2 text-muted"></i>
                <span class="item-name text-15 text-muted">{{ $t('clients.text') }}</span>
              </a>

              <ul class="mm-collapse ">

                <li class="item-name text-initial"  >
                  <router-link :to="{name: 'B2B-client' , state : {'type' : 'B2B-client'}}" class="link" >
                    <i class="fa-solid fa-dollar-sign mr-2"></i> <span class="text-muted"> {{ $t('clientsB2B.text') }} </span>
                  </router-link>
                </li>
                <li class="item-name text-initial"  >
                  <router-link :to="{name: 'B2B-partner' , state : {'type' : 'B2B-partner'}}" class="link" >
                    <i class="fa-solid fa-flag mr-2"></i> <span class="text-muted"> {{ $t('partnersB2B.text') }} </span>
                  </router-link>
                </li>
                <li class="item-name text-initial"  >
                  <router-link :to="{name: 'individual-partner' , state : {'type' : 'individual-partner'}}" class="link" >
                    <i class="fa-solid fa-vector-square mr-2"></i> <span class="text-muted"> {{ $t('individualsPartners.text') }} </span>
                  </router-link>
                </li>
                <li class="item-name text-initial"  >
                  <router-link :to="{name: 'contentPartners' , state : {'type' : 'contentPartners'}}" class="link" >
                    <i class="fa-solid fa-fill mr-2"></i> <span class="text-muted"> {{ $t('contentPartners.text') }} </span>
                  </router-link>
                </li>

              </ul>

            </li>
<!--            <li   class="Ul_li&#45;&#45;hover">-->
<!--              <router-link :to="{name: 'Clients'}" class="link" >-->
<!--                <i class="fa-solid fa-users text-20 mr-2 text-muted"></i>-->
<!--                <span class="item-name text-15 text-muted">{{ $t('clients.text') }}</span>-->
<!--              </router-link>-->
<!--            </li>-->

            <li class="Ul_li--hover">
              <router-link :to="{name: 'Users'}" class="link" >
                <i class="fa-solid fa-user text-20 mr-2 text-muted"></i>
                <span class="item-name text-15 text-muted">{{ $t('users.text') }}</span>
              </router-link>
            </li>

            <li class="Ul_li--hover">
              <router-link :to="{name: 'WorkTeams'}" class="link" >
                <i class="fa-solid fa-user text-20 mr-2 text-muted"></i>
                <span class="item-name text-15 text-muted">{{ $t('typesOfWorkTeams.text') }}</span>
              </router-link>
            </li>

            <!--            this route will move to db and come dynamic-->
<!-- 
            <li>
              <img class="p-2 bg-white my-3 rounded" :src="`${publicPath}assets/images/logo.png`" width="150" alt="">
            </li> -->

          </ul>

        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "app-sidebar",
  data() {
    return {
      currentSidebar : '',
      pages: [],
      publicPath: process.env.BASE_URL,
      menuIcons: ["i-Bar-Chart", "i-Library", "i-Suitcase", "i-Computer-Secure", "i-Computer-Secure", "i-File-Clipboard-File--Text", "i-File-Clipboard-File--Text", "i-File-Horizontal-Text", "i-Double-Tap", "i-Safe-Box1", "i-Bar-Chart", "i-File-Horizontal-Text", "i-Library",],
      loading: false
    }
  },
  mounted() {
  },
  methods: {

  },
  computed: {
    changeMenu() {
      return this.$store.state.dashboardData.leftmenuScroller == 'e';
    },

  },
  watch:{
    '$i18n.locale': function() {
    }
  }
}
</script>

<style scoped>
.link {
  opacity: .8;
  font-size: 14px;
}
.router-link-active {
  opacity: 1;
}
.gull-brand img {
  background: #fff;
  padding: 5px;
}
[dir="rtl"] .metismenu .has-arrow:after {
  left: 5px !important;
  right: auto !important;
}
.metismenu .has-arrow:after {
  right: 5px !important;
}
[dir="rtl"] .sidebar-compact-switch.ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}
</style>
