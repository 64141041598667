export default {
  "chgProfileData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الملف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chgProfileData"])}
  },
  "accountSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات الحساب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accountSettings"])}
  },
  "myInvoices": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فواتيرى"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myInvoices"])}
  },
  "logout": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logout"])}
  },
  "signIn": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signIn"])}
  },
  "fullName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم بالكامل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإسم بالكامل"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لإدخال الاسم كاملاً مع اللقب"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fullName"])}
  },
  "password": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لإدخال تعريف كلمة المرور الخاصة بالمستخدم  للدخول في النظام  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])}
  },
  "passwordConfirmation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دخال تعريف كلمة المرور الخاصة بالمستخدم  للدخول في النظام"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passwordConfirmation"])}
  },
  "countries": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدول"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countries"])}
  },
  "editCountry": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الدوله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editCountry"])}
  },
  "countryInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الدوله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryInfo"])}
  },
  "search": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])}
  },
  "ID": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعرف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
  },
  "countryName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدوله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدوله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم الدوله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryName"])}
  },
  "countryCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود الدوله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود الدوله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال كود الدوله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryCode"])}
  },
  "countryStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله الدوله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله الدوله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال حاله الدوله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryStatus"])}
  },
  "operations": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمليات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["operations"])}
  },
  "edit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])}
  },
  "toggleStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغير الحاله "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toggleStatus"])}
  },
  "noDataFound": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد بيانات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noDataFound"])}
  },
  "addCountry": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضاافه دوله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addCountry"])}
  },
  "cancel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغاء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])}
  },
  "allRightsReserved": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allRightsReserved"])}
  },
  "terms": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والأحكام"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms"])}
  },
  "requiredMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة مطلوبة لهذا الحقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requiredMsg"])}
  },
  "minLengthMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى للطول المسموح به هو 2 لهذا الحقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minLengthMsg"])}
  },
  "maxLengthMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى للطول المسموح به هو 200لهذا الحقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxLengthMsg"])}
  },
  "maxLengthMsgTextArea": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى للطول المسموح به هو 5000 لهذا الحقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxLengthMsgTextArea"])}
  },
  "alertLangValidation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى التأكد من إكمال جميع علامات التبويب الخاصة بمتطلبات اللغة!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alertLangValidation"])}
  },
  "minValue": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقل قيمة ممكنة هي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minValue"])}
  },
  "maxLengthMsgMobile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى للطول المسموح به هو 20 لهذا الحقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxLengthMsgMobile"])}
  },
  "minLengthMsgMobile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى للطول المسموح به هو 7 لهذا الحقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minLengthMsgMobile"])}
  },
  "numericValidationMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال ارقام فقط"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numericValidationMsg"])}
  },
  "depHasEducational": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القسم مرتبط بمرحلة دراسية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["depHasEducational"])}
  },
  "emailInvailed": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني غير صحيح"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["emailInvailed"])}
  },
  "alphaMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل يقبل اللغة الإنجليزية فقط"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alphaMsg"])}
  },
  "slugValidation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل يقبل اللغه الانجليزيه و الارقام و الرموز فقط"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slugValidation"])}
  },
  "noOptionsText": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القائمة فارغة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noOptionsText"])}
  },
  "noResultsText": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على نتائج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noResultsText"])}
  },
  "invalidUsernameOrPass": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد أدخلت اسم مستخدم أو كلمة مرور غير صالحة!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invalidUsernameOrPass"])}
  },
  "addCurrency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافه عمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addCurrency"])}
  },
  "editCurrency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editCurrency"])}
  },
  "currencyInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyInfo"])}
  },
  "currencies": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencies"])}
  },
  "currencyName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العمله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم العمله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyName"])}
  },
  "currencyCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود العمله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم العمله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyCode"])}
  },
  "currencyStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله العمله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم العمله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyStatus"])}
  },
  "exchangeRateCurrencies": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل تغير العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل تغير العمله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال معدل العمله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exchangeRateCurrencies"])}
  },
  "activeStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنشيط الحاله "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activeStatus"])}
  },
  "currencySymbol": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز العمله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم العمله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencySymbol"])}
  },
  "currencyFormat": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نمط العمله"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نمط العمله"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال نمط العمله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyFormat"])}
  },
  "save": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])}
  },
  "remove": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove"])}
  },
  "areYouSure": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متاكد؟"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["areYouSure"])}
  },
  "revertThis": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن استرجاع هذا العنصر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revertThis"])}
  },
  "deleteIt": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم احذف هذا"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deleteIt"])}
  },
  "seen": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاهدة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seen"])}
  },
  "to": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
  },
  "from": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])}
  },
  "records": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["records"])}
  },
  "first": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاول"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first"])}
  },
  "last": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاخير"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last"])}
  },
  "fields": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجالات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fields"])}
  },
  "fieldsCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافه حقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsCreate"])}
  },
  "fieldsUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل حقل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsUpdate"])}
  },
  "fieldsInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن المجال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsInfo"])}
  },
  "fieldName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المجال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المجال"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم المجال "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsName"])}
  },
  "fieldStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله المجال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله المجال"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال حاله المجال "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsStatus"])}
  },
  "sectors": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطاعات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectors"])}
  },
  "sectorsCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافه قطاع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافه قطاع"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsCreate"])}
  },
  "sectorsUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل قطاع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل قطاع"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsUpdate"])}
  },
  "sectorsInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن القطاع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن القطاع"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsInfo"])}
  },
  "sectorsName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القطاع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القطاع"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم القطاع  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsName"])}
  },
  "sectorsStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله القطاع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله القطاع"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال حاله القطاع "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsStatus"])}
  },
  "samples": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نماذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samples"])}
  },
  "samplesName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم النموذج"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال اسم النموذج  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesName"])}
  },
  "samplesStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله النموذج"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال حاله النموذج  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesStatus"])}
  },
  "samplesSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز النموذج"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال رمز النموذج  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesSlug"])}
  },
  "samplesCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء نموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesCreate"])}
  },
  "samplesUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesUpdate"])}
  },
  "samplesInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesInfo"])}
  },
  "units": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوحدات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["units"])}
  },
  "unitsCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء وحده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsCreate"])}
  },
  "unitsUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الوحده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsUpdate"])}
  },
  "unitsInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الوحده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsInfo"])}
  },
  "unitsName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الوحده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الوحده"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  اسم الوحده "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsName"])}
  },
  "unitsSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الوحده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الوحده"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  رمز الوحده "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsSlug"])}
  },
  "unitsStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله الوحده"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله الوحده"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  حاله الوحده "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsStatus"])}
  },
  "pleaseWait": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك انتظر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseWait"])}
  },
  "active": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفعل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])}
  },
  "inactive": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير مفعل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactive"])}
  },
  "update": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["update"])}
  },
  "basicData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الاساسية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicData"])}
  },
  "productSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات المنتج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productSettings"])}
  },
  "productInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات المنتج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productInfo"])}
  },
  "modelUnit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النماذج والوحدات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelUnit"])}
  },
  "paymentSystem": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام الدفع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paymentSystem"])}
  },
  "packages": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباقات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packages"])}
  },
  "packagesCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء باقه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesCreate"])}
  },
  "packagesName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الباقه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الباقه"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  اسم الباقه  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesName"])}
  },
  "packagesSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الباقه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الباقه"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  رمز الباقه  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesSlug"])}
  },
  "packagesStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله الباقه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله الباقه"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  حاله الباقه  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesStatus"])}
  },
  "packagesUnits": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوحدات الخاصه بالباقه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوحدات الخاصه بالباقه"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  الوحدات لخاصه بالباقه  "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesUnits"])}
  },
  "packagesInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الباقه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesInfo"])}
  },
  "packagesUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الباقه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesUpdate"])}
  },
  "UltimateSolutions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركة الحلول النهائية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UltimateSolutions"])}
  },
  "otherSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات اخرى"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otherSettings"])}
  },
  "productName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المنتج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productName"])}
  },
  "productStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله لمنتج "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productStatus"])}
  },
  "domain": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدومين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["domain"])}
  },
  "language": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])}
  },
  "ar": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar"])}
  },
  "en": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإنجليزية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])}
  },
  "clientCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود العميل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientCode"])}
  },
  "tradeName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم التجاري"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tradeName"])}
  },
  "productLogo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شعارالمنتج Logo"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productLogo"])}
  },
  "shoppingCountry": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلد التسوق"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shoppingCountry"])}
  },
  "additionalInformation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات اضافية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additionalInformation"])}
  },
  "dropFiles": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم باسقاط الملف هنا"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dropFiles"])}
  },
  "yes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])}
  },
  "no": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])}
  },
  "fromModel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انطلاقا من نموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fromModel"])}
  },
  "chooseUnits": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الوحدات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fromModel"])}
  },
  "MCQ": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسألة بخيارات MCQ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQ"])}
  },
  "exam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإختبارات والتمارين + EXAM"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exam"])}
  },
  "HR": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموارد البشرية HR"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HR"])}
  },
  "library": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكتبة Library"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["library"])}
  },
  "forum": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتديات Forum "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forum"])}
  },
  "chooseModel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر النموذج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseModel"])}
  },
  "ZidneyAcademySkills": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زدني أكاديمية, زدني مهارات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZidneyAcademySkills"])}
  },
  "chooseBasicData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر البيانات الأساسية "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseBasicData"])}
  },
  "division": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["division"])}
  },
  "seasons": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفصول"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seasons"])}
  },
  "materials": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["materials"])}
  },
  "categories": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيفات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["categories"])}
  },
  "other": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other"])}
  },
  "productOwnership": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملكية المنتج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productOwnership"])}
  },
  "specialZidney": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خاص بزدني "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specialZidney"])}
  },
  "specificCustomer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خاص بعميل B2B"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specificCustomer"])}
  },
  "productZidneyPayment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" منتج تابع لنظام الدفع زدني "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specificCustomer"])}
  },
  "commissionZidney": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمولة زدني عن كل مستخدم "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissionZidney"])}
  },
  "limitedContentSet": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات المحتوى المحدود"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limitedContentSet"])}
  },
  "contain": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحتوى"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contain"])}
  },
  "limited": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محدود"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limited"])}
  },
  "unlimited": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير محدود"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unlimited"])}
  },
  "basicDataName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم البيانات الاساسية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم البيانات الاساسية"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  اسم البيانات الاساسية "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataName"])}
  },
  "basicDataSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز البيانات الاساسية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز البيانات الاساسية"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  رمز البيانات الاساسية "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataSlug"])}
  },
  "basicDataStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله البيانات الاساسية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله البيانات الاساسية"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  حاله البيانات الاساسية "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataStatus"])}
  },
  "basicDataCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء بيانات اساسيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataCreate"])}
  },
  "basicDataShowInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن بيانات اساسيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataShowInfo"])}
  },
  "basicDataUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل بيانات اساسيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataUpdate"])}
  },
  "lamitedContent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الاقصي للمحتوي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الاقصي للمحتوي"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لإدخال  الحد االاقصي للمحتوي   "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lamitedContent"])}
  },
  "clientProducts": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientProducts"])}
  },
  "products": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["products"])}
  },
  "productPrices": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسعار المنتج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productPrices"])}
  },
  "clientsB2B": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملاء B2B"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientsB2B"])}
  },
  "partnersB2B": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركاء B2B"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partnersB2B"])}
  },
  "individualsPartners": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركاء أفراد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individualsPartners"])}
  },
  "name": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])}
  },
  "status": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل لادخال الحاله"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])}
  },
  "email": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايميل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])}
  },
  "type": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])}
  },
  "target": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطابع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["target"])}
  },
  "phone": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاتف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])}
  },
  "address": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])}
  },
  "create": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create"])}
  },
  "clientName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العميل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientName"])}
  },
  "webSite": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع الانترنت"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webSite"])}
  },
  "governmental": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حكومي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["governmental"])}
  },
  "privateSector": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطاع خاص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privateSector"])}
  },
  "profit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربحي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profit"])}
  },
  "nonProfit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير ربحي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NonProfit"])}
  },
  "sector": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector"])}
  },
  "areasInterest": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجالات الإهتمام"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["areasInterest"])}
  },
  "description": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])}
  },
  "PromoCodes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرموز الترويجية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PromoCodes"])}
  },
  "codeName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الرمز"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["codeName"])}
  },
  "commissionPercentage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة العمولة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissionPercentage"])}
  },
  "discountPercentage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الخصم %"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discountPercentage"])}
  },
  "activationStart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بداية التفعيل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activationStart"])}
  },
  "activationEnd": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهاية التفعيل "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activationEnd"])}
  },
  "code": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرمز"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])}
  },
  "branchOf": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خاص بـ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branchOf"])}
  },
  "PromoCodeType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الرمز الترويجي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PromoCodeType"])}
  },
  "general": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عام"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general"])}
  },
  "forCompanies": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للشركات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forCompanies"])}
  },
  "forIndividuals": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للأفراد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forIndividuals"])}
  },
  "forSubscribers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للمشتركين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forSubscribers"])}
  },
  "clientType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نوع العميل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forSubscribers"])}
  },
  "users": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدمين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["users"])}
  },
  "activated": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفعل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activated"])}
  },
  "notActivated": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير مفعل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notActivated"])}
  },
  "clients": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملاء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clients"])}
  },
  "dashboard": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dashboard"])}
  },
  "productPrice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة التسعير"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productPrice"])}
  },
  "addPrice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة تسعير"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addPrice"])}
  },
  "productsPricesCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة - تعديل سعر المنتج "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productsPricesCreate"])}
  },
  "country": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])}
  },
  "currency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currency"])}
  },
  "passwordConfirmation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passwordConfirmation"])}
  },
  "from500to2000": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من 500 الي 2000"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from500to2000"])}
  },
  "oneUser": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدم واحد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oneUser"])}
  },
  "oneMonth": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oneMonth"])}
  },
  "threeMonths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 اشهر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["threeMonths"])}
  },
  "sixMonths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 اشهر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sixMonths"])}
  },
  "twelveMonths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 شهر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["twelveMonths"])}
  },
  "userNumbers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المستخدمين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["userNumbers"])}
  },
  "productInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" معلومات عن المنتج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productInfo"])}
  },
  "duration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duration"])}
  },
  "universal": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يونيفرسال"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["universal"])}
  },
  "pleaseReviseData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء مراجعة البيانات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseReviseData"])}
  },
  "TrialSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادت النسخة التجريبية "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TrialSettings"])}
  },
  "productCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترميز المنتج "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productCode"])}
  },
  "addProduct": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة منتج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addProduct"])}
  },
  "productDescription": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف المنتج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productDescription"])}
  },
  "usersCount": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المستحدمين"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usersCount"])}
  },
  "backOfficeUsers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد مستخدمين الباك اوفيس"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backOfficeUsers"])}
  },
  "durationOfUse": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الاستخدام"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durationOfUse"])}
  },
  "licenses": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التراخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenses"])}
  },
  "license": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["license"])}
  },
  "client": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العميل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["client"])}
  },
  "product": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتج"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product"])}
  },
  "activation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفعيل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activation"])}
  },
  "shoppingCountry": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلد التسوق"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shoppingCountry"])}
  },
  "startOfActivation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بداية التفعيل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["startOfActivation"])}
  },
  "activationExpires": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتهاء التفعيل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activationExpires"])}
  },
  "addLicense": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة ترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addLicense"])}
  },
  "licenseDuration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseDuration"])}
  },
  "price": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])}
  },
  "additionalInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات اضافية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additionalInfo"])}
  },
  "licenseNumber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseNumber"])}
  },
  "licenseStartDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ بداية الترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseStartData"])}
  },
  "licenseEndDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ نهاية الترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseEndData"])}
  },
  "add": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])}
  },
  "rangedUsers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدى المستخدم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rangedUsers"])}
  },
  "removeRange": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف المدى"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["removeRange"])}
  },
  "addRange": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مدى"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addRange"])}
  },
  "for1month": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لشهر واحد"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for1month"])}
  },
  "for3month": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لثلاثة أشهر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for3month"])}
  },
  "for6month": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لستة أشهر"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for6month"])}
  },
  "for12month": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لسنة"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for12month"])}
  },
  "subdomain": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdomain"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subdomain"])}
  },
  "localCurrency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملية محلية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["localCurrency"])}
  },
  "foreignCurrency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملة أجنبية"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foreignCurrency"])}
  },
  "hasPromoCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديك رمز ترويجي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasPromoCode"])}
  },
  "promoCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرمز الترويجي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promoCode"])}
  },
  "discount": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخصم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discount"])}
  },
  "description": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])}
  },
  "priceAfter": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر بعد الخصم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priceAfter"])}
  },
  "additionalDiscount": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خصم اضافي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additionalDiscount"])}
  },
  "netPaymentAmount": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صافي مبلغ الدفع"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["netPaymentAmount"])}
  },
  "licenseInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الترخيص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseInfo"])}
  },
  "promoCodeInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الرمز الترويجي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promoCodeInfo"])}
  },
  "contentPartners": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركاء بمحتوي"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contentPartners"])}
  },
  "settings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحسابات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["settings"])}
  },
  "mailsmsTemplates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قوالب البريد / الرسائل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mailsmsTemplates"])}
  },
  "listTemplates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة القوالب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["listTemplates"])}
  },
  "templateTitle": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان القالب"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["templateTitle"])}
  },
  "addTemplate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة قالب"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addTemplate"])}
  },
  "editTemplate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل قالب"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editTemplate"])}
  },
  "mailTemplate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قالب بريد الكترونى"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mailTemplate"])}
  },
  "smsTemplate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قالب SMS"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["smsTemplate"])}
  },
  "allowedVars": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتغيرات المتاح استخدامها"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allowedVars"])}
  },
  "alerts": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التنبيهات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alerts"])}
  },
  "addAlert": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة تنبيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addAlert"])}
  },
  "editAlert": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل تنبيه"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editAlert"])}
  },
  "action": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدث"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["action"])}
  },
  "partners": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركاء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partners"])}
  },
  "teams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريق العملاء"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teams"])}
  },
  "customStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل خاص"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customStatus"])}
  },
  "typesOfWorkTeams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنواع فرق العمل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typesOfWorkTeams"])}
  },
  "workTeamName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم  نوع فريق العمل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workTeamName"])}
  },
  "addWorkTeam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة  نوع فريق العمل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addWorkTeam"])}
  },
  "editWorkTeam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل  نوع فريق العمل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editWorkTeam"])}
  },
  "workTeamInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات  نوع فريق العمل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workTeamInfo"])}
  },
  "alertsInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن التنبيهات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alertsInfo"])}
  },
  "profilesRoles": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملفات الشخصية والأدوار"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profilesRoles"])}
  },
  "profileName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم البروفايل"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profileName"])}
  },
  "permissions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصلاحيات"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permissions"])}
  },
  "username": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستحدم"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username"])}
  },
  "role": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدور"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["role"])}
  }
}