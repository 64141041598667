import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
   {
    path: '/countries',
    name: 'Countries',
    component: () => import ('../components/Countries/List.vue'),
    children: [
      {
        path: 'create',
        name: 'CountriesCreate',
        component: () => import ('../components/Countries/Create.vue')
      }, {
        path: 'update',
        name: 'CountriesUpdate',
        component: () => import ('../components/Countries/Update.vue')
      }, {
        path: 'show',
        name: 'CountriesShow',
        component: () => import ('../components/Countries/Show.vue')
      }
    ]
  }, 
   {
    path: '/currencies',
    name: 'Currencies',
    component: () => import ('../components/Currencies/List.vue'),
    children: [
      {
        path: 'create',
        name: 'CurrenciesCreate',
        component: () => import ('../components/Currencies/Create.vue')
      }, {
        path: 'update',
        name: 'CurrenciesUpdate',
        component: () => import ('../components/Currencies/Update.vue')
      }
      , {
        path: 'show',
        name: 'CurrenciesShow',
        component: () => import ('../components/Currencies/Show.vue')
      }
    ]
  },
   {
    path: '/fields',
    name: 'Fields',
    component: () => import ('../components/Fields/List.vue'),
    children: [
      {
        path: 'create',
        name: 'FieldsCreate',
        component: () => import ('../components/Fields/Create.vue')
      }, {
        path: 'update',
        name: 'FieldsUpdate',
        component: () => import ('../components/Fields/Update.vue')
      }
      , {
        path: 'show',
        name: 'FieldsShow',
        component: () => import ('../components/Fields/Show.vue')
      }
    ]
  },
   {
    path: '/sectors',
    name: 'Sectors',
    component: () => import ('../components/Sectors/List.vue'),
    children: [
      {
        path: 'create',
        name: 'SectorsCreate',
        component: () => import ('../components/Sectors/Create.vue')
      }, {
        path: 'update',
        name: 'SectorsUpdate',
        component: () => import ('../components/Sectors/Update.vue')
      }
      , {
        path: 'show',
        name: 'SectorsShow',
        component: () => import ('../components/Sectors/Show.vue')
      }
    ]
  },
   {
    path: '/samples',
    name: 'Samples',
    component: () => import ('../components/Samples/List.vue'),
    children: [
      {
        path: 'create',
        name: 'SamplesCreate',
        component: () => import ('../components/Samples/Create.vue')
      }, {
        path: 'update',
        name: 'SamplesUpdate',
        component: () => import ('../components/Samples/Update.vue')
      }
      , {
        path: 'show',
        name: 'SamplesShow',
        component: () => import ('../components/Samples/Show.vue')
      }
    ]
  },
   {
    path: '/units',
    name: 'Units',
    component: () => import ('../components/Units/List.vue'),
    children: [
      {
        path: 'create',
        name: 'UnitsCreate',
        component: () => import ('../components/Units/Create.vue')
      }, {
        path: 'update',
        name: 'UnitsUpdate',
        component: () => import ('../components/Units/Update.vue')
      }
      , {
        path: 'show',
        name: 'UnitsShow',
        component: () => import ('../components/Units/Show.vue')
      }
    ]
  },
   {
    path: '/packages',
    name: 'Packages',
    component: () => import ('../components/Packages/List.vue'),
    children: [
      {
        path: 'create',
        name: 'PackagesCreate',
        component: () => import ('../components/Packages/Create.vue')
      }, {
        path: 'update',
        name: 'PackagesUpdate',
        component: () => import ('../components/Packages/Update.vue')
      }
      , {
        path: 'show',
        name: 'PackagesShow',
        component: () => import ('../components/Packages/Show.vue')
      }
    ]
  },
   {
    path: '/basic-data',
    name: 'BasicData',
    component: () => import ('../components/BasicData/List.vue'),
    children: [
      {
        path: 'create',
        name: 'BasicDataCreate',
        component: () => import ('../components/BasicData/Create.vue')
      }, {
        path: 'update',
        name: 'BasicDataUpdate',
        component: () => import ('../components/BasicData/Update.vue')
      }
      , {
        path: 'show',
        name: 'BasicDataShow',
        component: () => import ('../components/BasicData/Show.vue')
      }
    ]
  },
   {
        path: '/products',
        name: 'Products',
        component: () => import ('../components/Product/List.vue'),
        children: [
          {
            path: 'create',
            name: 'ProductCreate',
            component: () => import ('../components/Product/Create.vue')
          }, {
            path: 'update',
            name: 'ProductUpdate',
            component: () => import ('../components/Product/Update.vue')
          },
          {
            path: 'show',
            name: 'ProductsShow',
            component: () => import ('../components/Product/Show.vue')
          }
        ]
      },
   {
        path: '/products-prices',
        name: 'ProductsPrices',
        component: () => import ('../components/ProductsPrices/List.vue'),
        props: true,
        children: [
          {
            path: 'create',
            name: 'ProductsPricesCreate',
            component: () => import ('../components/ProductsPrices/Create.vue')
          }, {
            path: 'update',
            name: 'ProductsPricesUpdate',
            component: () => import ('../components/ProductsPrices/Update.vue')
          }
          , {
            path: 'show',
            name: 'ProductsPricesShow',
            component: () => import ('../components/ProductsPrices/Show.vue')
          }
        ]
      },
   {
        path: '/clients/B2B',
        name: 'B2B-client',
        component: () => import ('../components/Clients/List.vue'),
        children: [
          {
            path: 'create',
            name: 'ClientsCreate',
            component: () => import ('../components/Clients/Create.vue')
          },
          {
            path: 'update',
            name: 'ClientsUpdate',
            component: () => import ('../components/Clients/Update.vue')
          }
        ]
      },
   {
        path: '/clients/partners-B2B',
        name: 'B2B-partner',
        component: () => import ('../components/Clients/List.vue'),
        children: [
          {
            path: 'create',
            name: 'ClientsCreate',
            component: () => import ('../components/Clients/Create.vue')
          },
          {
            path: 'update',
            name: 'ClientsUpdate',
            component: () => import ('../components/Clients/Update.vue')
          }
        ]
      },
   {
        path: '/clients/individuals-partners',
        name: 'individual-partner',
        component: () => import ('../components/Clients/List.vue'),
        children: [
          {
            path: 'create',
            name: 'ClientsCreate',
            component: () => import ('../components/Clients/Create.vue')
          },
          {
            path: 'update',
            name: 'ClientsUpdate',
            component: () => import ('../components/Clients/Update.vue')
          }
        ]
      },
   {
        path: '/clients/content-partners',
        name: 'contentPartners',
        component: () => import ('../components/Clients/List.vue'),
        children: [
          {
            path: 'create',
            name: 'ClientsCreate',
            component: () => import ('../components/Clients/Create.vue')
          },
          {
            path: 'update',
            name: 'ClientsUpdate',
            component: () => import ('../components/Clients/Update.vue')
          }
        ]
      },
      {
        path: '/profiles-roles',
        name: 'ProfilesRoles',
        component: () => import ('../components/ProfilesRoles/List.vue'),
        children: [
          {
            path: 'create',
            name: 'ProfilesRolesCreate',
            component: () => import ('../components/ProfilesRoles/Create.vue')
          },
          {
            path: 'update',
            name: 'ProfilesRolesUpdate',
            component: () => import ('../components/ProfilesRoles/Update')
          },
          {
            path: 'show',
            name: 'ProfilesRolesShow',
            component: () => import ('../components/ProfilesRoles/Show.vue')
          },
        ]
      },
      {
        path: '/promo-codes',
        name: 'PromoCodes',
        component: () => import ('../components/PromoCodes/List.vue'),
        children: [
          {
            path: 'create',
            name: 'PromoCodesCreate',
            component: () => import ('../components/PromoCodes/Create.vue')
          },
          {
            path: 'update',
            name: 'PromoCodesUpdate',
            component: () => import ('../components/PromoCodes/Update')
          },
          {
            path: 'show',
            name: 'PromoCodesShow',
            component: () => import ('../components/PromoCodes/Show.vue')
          },
        ]
      }, 
      {
        path: '/users',
        name: 'Users',
        component: () => import ('../components/Users/List.vue'),
        children: [
          {
            path: 'create',
            name: 'UsersCreate',
            component: () => import ('../components/Users/Create.vue')
          },
          {
            path: 'update',
            name: 'UsersUpdate',
            component: () => import ('../components/Users/Update.vue')
          }
        ]
      },
   {
        path: '/licenses',
        name: 'Licenses',
        component: () => import ('../components/Licenses/List.vue'),
        children: [
          {
            path: 'create',
            name: 'LicensesCreate',
            component: () => import ('../components/Licenses/Create.vue')
          },
          {
            path: 'update',
            name: 'LicensesUpdate',
            component: () => import ('../components/Licenses/Update.vue')
          },
          {
            path: 'show',
            name: 'LicensesShow',
            component: () => import ('../components/Licenses/Show.vue')
          },
          {
            path: 'renew',
            name: 'LicensesRenew',
            component: () => import ('../components/Licenses/Renew.vue')
          },
        ]
      },
   {
        path: '/sms-templates',
        name: 'SMSTemplates',
        component: () => import ('../components/SmsTemplates/List.vue'),
        children: [
          {
            path: 'create',
            name: 'SMSTemplatesCreate',
            component: () => import ('../components/SmsTemplates/Create.vue')
          }, {
            path: 'update',
            name: 'SMSTemplatesUpdate',
            component: () => import ('../components/SmsTemplates/Update.vue')
          }, {
            path: 'show',
            name: 'SMSTemplatesShow',
            component: () => import ('../components/SmsTemplates/Show.vue')
          }
        ]
      },
    {
            path: '/alerts',
            name: 'Alerts',
            component: () => import ('../components/Alerts/List.vue'),
            children: [
                {
                    path: 'create',
                    name: 'AlertsCreate',
                    component: () => import ('../components/Alerts/Create.vue')
                }, {
                    path: 'update',
                    name: 'AlertsUpdate',
                    component: () => import ('../components/Alerts/Update.vue')
                }, {
                    path: 'show',
                    name: 'AlertsShow',
                    component: () => import ('../components/Alerts/Show.vue')
                }
            ]
        },
        {
            path: '/work-teams',
            name: 'WorkTeams',
            component: () => import ('../components/WorkTeams/List.vue'),
            children: [
                {
                    path: 'create',
                    name: 'WorkTeamsCreate',
                    component: () => import ('../components/WorkTeams/Create.vue')
                }, {
                    path: 'update',
                    name: 'WorkTeamsUpdate',
                    component: () => import ('../components/WorkTeams/Update.vue')
                }, {
                    path: 'show',
                    name: 'WorkTeamsShow',
                    component: () => import ('../components/WorkTeams/Show.vue')
                }
            ]
        },



    ]

  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeResolve((to, from, next) => {
  const publicPages = ['Login'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = store.getters['auth/authenticated'];
  const isOnline = navigator?.onLine ?? true;
  console.log("yasooooo",isOnline);
  if (authRequired && !loggedIn  && isOnline) {
    return next('/login');
  }
  if(loggedIn && !authRequired) {
    return next('/');
  }
  next();
})

export default router
